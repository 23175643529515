"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OWQuestRewardToJSON = exports.OWQuestRewardFromJSONTyped = exports.OWQuestRewardFromJSON = void 0;
var _1 = require("./");
function OWQuestRewardFromJSON(json) {
    return OWQuestRewardFromJSONTyped(json, false);
}
exports.OWQuestRewardFromJSON = OWQuestRewardFromJSON;
function OWQuestRewardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': json['amount'],
        'rewardType': (0, _1.OWRewardTypeFromJSON)(json['rewardType']),
    };
}
exports.OWQuestRewardFromJSONTyped = OWQuestRewardFromJSONTyped;
function OWQuestRewardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'rewardType': (0, _1.OWRewardTypeToJSON)(value.rewardType),
    };
}
exports.OWQuestRewardToJSON = OWQuestRewardToJSON;
