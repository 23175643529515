"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RewardResponseObjectToJSON = exports.RewardResponseObjectFromJSONTyped = exports.RewardResponseObjectFromJSON = void 0;
var _1 = require("./");
function RewardResponseObjectFromJSON(json) {
    return RewardResponseObjectFromJSONTyped(json, false);
}
exports.RewardResponseObjectFromJSON = RewardResponseObjectFromJSON;
function RewardResponseObjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rewardType': (0, _1.OWRewardTypeFromJSON)(json['rewardType']),
        'amount': json['amount'],
    };
}
exports.RewardResponseObjectFromJSONTyped = RewardResponseObjectFromJSONTyped;
function RewardResponseObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rewardType': (0, _1.OWRewardTypeToJSON)(value.rewardType),
        'amount': value.amount,
    };
}
exports.RewardResponseObjectToJSON = RewardResponseObjectToJSON;
